import { useState, useEffect } from "react";

import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCardMenuVelti";
import MiniLoadingCard from "examples/Cards/Loading";

// React icons
import { IoReload } from "react-icons/io5";
import { IoPlaySharp } from "react-icons/io5";
import { IoStop } from "react-icons/io5";

// Service Axios
import api from "services/api";

// Config local
import config from ".config.json";

// Logo Velti
import logoVelti from "assets/images/logos/logo-velti.png";

const TOMCAT_STATUS = "TOMCAT RUNNING.";
const TOMCAT_START = "TOMCAT STARTED.";
const TOMCAT_STOP = "TOMCAT STOPED.";
const TOMCAT_RESTART = "TOMCAT RESTARTED.";

function MiniCardVelti() {
  const tokenUser = localStorage.getItem("token");
  const [refreshInterval, setRefreshInterval] = useState(60000);
  const [loading, setLoading] = useState(true);
  const [countAllLinks, setCountAllLinks] = useState(0);
  const [veltiStatus, setVeltiStatus] = useState({ status: false, pid: 0 });

  async function setStatus() {
    setLoading(true);
    // console.log(">>> MiniCardVelti.config:", config);
    if (config.localDev === "seap") {
      const res = await api.get("velti/status");
      console.log(">>>", (res.data.pid ? res.data.pid || 0 : 0) > 0);
      setVeltiStatus({
        status:
          res.data.status.toLocaleUpperCase() === TOMCAT_STATUS &&
          (res.data.pid ? res.data.pid || 0 : 0) > 0,
        pid: res.data.pid ? res.data.pid || 0 : 0,
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  async function setStart() {
    setLoading(true);
    const res = await api.get("velti/start", { authorization: tokenUser });
    console.log(">>>", {
      status: res.data.status.toLocaleUpperCase() === TOMCAT_START,
      pid: res.data.pid,
    });
    setVeltiStatus({
      status: res.data.status.toLocaleUpperCase() === TOMCAT_START,
      pid: res.data.pid,
    });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  async function setStop() {
    setLoading(true);
    const res = await api.get("velti/stop", { authorization: tokenUser });
    console.log(">>>", {
      status: !(res.data.status.toLocaleUpperCase() === TOMCAT_STOP),
      pid: res.data.pid,
    });
    setVeltiStatus({
      status: !(res.data.status.toLocaleUpperCase() === TOMCAT_STOP),
      pid: res.data.pid,
    });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  async function setRestart() {
    setLoading(true);
    const res = await api.get("velti/restart", { authorization: tokenUser });
    console.log(">>>", {
      status: res.data.status.toLocaleUpperCase() === TOMCAT_RESTART,
      pid: res.data.pid,
    });
    setVeltiStatus({
      status: res.data.status.toLocaleUpperCase() === TOMCAT_RESTART,
      pid: res.data.pid,
    });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  useEffect(() => {
    setStatus();
  }, [false]);

  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(setStatus, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);

  if (loading) {
    return <MiniLoadingCard />;
  } else {
    return (
      <MiniStatisticsCard
        buttonAddImage={{
          icon: {
            color: "white",
            component: <IoReload size="32px" color="black" />,
            exec: async () => {
              await setRestart();
            },
          },
        }}
        buttonEditImage={{
          icon: {
            color: "white",
            component: <IoPlaySharp size="32px" color="black" />,
            exec: () => {
              setStart();
            },
          },
        }}
        buttonDeleteImage={{
          icon: {
            color: "white",
            component: <IoStop size="32px" color="black" />,
            exec: () => {
              setStop();
            },
          },
        }}
        direction="left"
        title={{ text: "Velti Status: (" + veltiStatus.pid + ")" }}
        count={""}
        percentage={{
          color: veltiStatus.status ? "success" : "error",
          text: veltiStatus.status ? "Online" : "Offline",
        }}
        icon={{
          color: veltiStatus.status ? "success" : "error",
          component: <img src={logoVelti} alt="" style={{ height: 32 }} />,
        }}
      />
    );
  }
}

export default MiniCardVelti;
