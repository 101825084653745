import { useState, useEffect } from "react";
import { useLocation, Redirect } from "react-router-dom";

import moment from "moment";

import Grid from "@mui/material/Grid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Authentication layout components
import BasicLayout from "../components/BasicLayout";

import ClockTimeStatus from "./components/ClockTimeStatus";
import LinkStatus from "./components/LinkStatus";
import PrinterStatus from "./components/PrinterStatus";
import SecurityDeviceStatus from "./components/SecurityDeviceStatus";

//
import MiniCardCftv from "./components/MiniCardCftv";
import MiniCardClocks from "./components/MiniCardClocks";
import MiniCardLinks from "./components/MiniCardLinks";
import MiniCardPrinters from "./components/MiniCardPrinters";
import MiniCardDevices from "./components/MiniCardDevices";
import MiniCardVoIPs from "./components/MiniCardVoIPs";
import MiniCardVelti from "./components/MiniCardVelti";
import MiniCardUnits from "./components/MiniCardUnits";

import BannerShow from "./components/BannerShow";

// Vision UI Dashboard React contexts
import { useVisionUIController, setUserLogged } from "context";

import {
  GROUP_ADMINS,
  GROUP_LAN_SUPPORT,
  GROUP_PRINTERS,
  GROUP_ASCOM,
  GROUP_DEVICES,
  GROUP_VELTI,
} from "variables/groups";

function groupAdmins(id_group) {
  return id_group === GROUP_ADMINS || id_group === GROUP_LAN_SUPPORT;
}

function groupCommunications(id_group) {
  return id_group === GROUP_ASCOM;
}

function groupPrinters(id_group) {
  return id_group === GROUP_PRINTERS;
}

function groupDevices(id_group) {
  return id_group === GROUP_DEVICES;
}

function groupVelti(id_group) {
  return id_group === GROUP_VELTI;
}

function DashAdmin() {
  const [controller, dispatch] = useVisionUIController();
  const userLogged = JSON.parse(localStorage.getItem("user"));
  const timeStampStorage = parseInt(localStorage.getItem("timeStampOut"));
  const [toDashboard, setToDashboard] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(1000);
  const [timeStampCurrent, setTimeStampCurrent] = useState(timeStampStorage - new Date().getTime());

  function updateTime() {
    const dateNow = new Date();
    const nowTimeStamp = dateNow.getTime();

    setTimeStampCurrent(timeStampStorage - nowTimeStamp);

    if (nowTimeStamp > timeStampStorage) {
      localStorage.clear();
      setToDashboard(true);
    }
  }

  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(updateTime, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);

  useEffect(() => {
    setUserLogged(dispatch, userLogged);
  }, []);

  if (toDashboard) {
    return <Redirect to="/" />;
  }

  return (
    <BasicLayout
      action={{
        type: "internal",
        label: "Logout",
        route: "/",
        exec: () => {
          localStorage.clear();
          setToDashboard(true);
        },
        color: "success",
        progress: {
          max: 100,
          current: parseInt((moment(timeStampCurrent).format("mm") * 60) / 10),
        },
      }}
    >
      {groupCommunications(userLogged.id_group) && (
        // {groupAdmins(userLogged.id_group) && (
        <Grid container mt={2} spacing={2} justifyContent="center">
          <Grid item xs={12} md={6} xl={9}>
            <BannerShow />
            <VuiBox mt={32} />
          </Grid>
        </Grid>
      )}

      {groupAdmins(userLogged.id_group) && (
        <Grid container mt={2} spacing={2} justifyContent="center">
          <Grid item xs={12} md={6} xl={3}>
            <MiniCardDevices />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <MiniCardVelti />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <MiniCardVoIPs />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <MiniCardUnits />
          </Grid>
        </Grid>
      )}

      <Grid container mt={2} spacing={2} justifyContent="center">
        {(groupAdmins(userLogged.id_group) ||
          groupDevices(userLogged.id_group) ||
          groupVelti(userLogged.id_group)) && (
          <Grid item xs={12} md={6} xl={3}>
            {/* <MiniCardLinks /> */}
            {/* <VuiBox mt={2} /> */}
            <LinkStatus />
          </Grid>
        )}

        {(groupAdmins(userLogged.id_group) || groupPrinters(userLogged.id_group)) && (
          <Grid item xs={12} md={6} xl={3}>
            {/* <MiniCardPrinters /> */}
            {/* <VuiBox mt={2} /> */}
            <PrinterStatus />
          </Grid>
        )}

        {(groupAdmins(userLogged.id_group) || groupVelti(userLogged.id_group)) && (
          <Grid item xs={12} md={6} xl={3}>
            {/* <MiniCardClocks /> */}
            {/* <VuiBox mt={2} /> */}
            <ClockTimeStatus />
          </Grid>
        )}

        {(groupAdmins(userLogged.id_group) || groupDevices(userLogged.id_group)) && (
          <Grid item xs={12} md={6} xl={3}>
            {/* <MiniCardCftv /> */}
            {/* <VuiBox mt={2} /> */}
            <SecurityDeviceStatus />
          </Grid>
        )}
      </Grid>
    </BasicLayout>
  );
}

function CheckLogin() {
  const location = useLocation();
  const isLoggedStorage = localStorage.getItem("logged");
  return isLoggedStorage ? (
    <DashAdmin />
  ) : (
    <Redirect
      to={
        "/login?redirect=" +
        location.pathname +
        (location.search !== "" ? `${location.search}` : "")
      }
    />
  );
}

export default CheckLogin;
