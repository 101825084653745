import { useLocation, Redirect } from "react-router-dom";

import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "examples/Footer";

import checkExpiredToken from "helpers/checkExpiredToken";

import FromRegister from "./components/FormRegister";

function Register() {
  return (
    <PageLayout>
      <FromRegister />
    </PageLayout>
  );
}

function CheckLogin() {
  const location = useLocation();
  const isLogged = true; //checkExpiredToken();
  return isLogged ? (
    <div style={{ textAlign: "center" }}>
      <Register />
    </div>
  ) : (
    <Redirect
      to={
        "/login?redirect=" +
        location.pathname +
        (location.search !== "" ? `${location.search}` : "")
      }
    />
  );
}

export default CheckLogin;
